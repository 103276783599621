import type { AppProps } from 'next/app';

import { shouldUseMsw } from 'tests/mocks/utils';

import 'styles/global.css';

if (shouldUseMsw()) {
  require('../tests/mocks');
}

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
    </>
  );
}

export default App;
