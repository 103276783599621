export const MSW_API_URL = process.env.MSW_API_URL ?? 'http://localhost:3000';

/**
 * Constructs a string with the MSW_API_URL as the base url and
 * the passed string as path.
 *
 * Example usage:
 *
 * rest.get(mockApiUrl('me/'), (_req, res, ctx) => {
 *   return res(ctx.json(meResponseData));
 * })
 *
 */
export const mswApiUrl = (str: TemplateStringsArray) => `${MSW_API_URL}/${str}`;

/**
 * Mock service worker will run by default in:
 * - Storybook
 * - Cypress
 * - Jest / React-testing-library
 *
 * Set NEXT_PUBLIC_USE_MOCK_API to true in env
 * to use it locally. Can be useful for debugging
 * purposes or when writing a test.
 */
export const shouldUseMsw = () => {
  return (
    process.env.NEXT_PUBLIC_USE_MOCK_API === 'true' ||
    process.env.STORYBOOK === 'true' ||
    process.env.CYPRESS === 'true' ||
    process.env.NODE_ENV === 'test'
  );
};
