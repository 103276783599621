import { rest, setupWorker } from 'msw';

import handlers from './handlers';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    msw: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isMockReady: any;
  }
}

// This configures a Service Worker with the given request handlers.
const worker = setupWorker(...handlers);

window.msw = {
  worker,
  rest,
};

export default worker;
