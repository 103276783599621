/* eslint-disable @typescript-eslint/no-var-requires */

if (typeof window === 'undefined') {
  const { default: server } = require('./server');
  server.listen();
} else {
  const { default: worker } = require('./worker');
  window.isMockReady = worker.start().then(() => {
    window.isMockReady = true;
  });
}

// Export something to silence linter
export {};
